<template>
<div>profile.vue</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
